<template>
  <div class="d-md-flex">
    <cards-list responsive="3" grid="6" gap="16" class="w-100 list-items">
      <router-link
        :to="menu.url"
        v-for="(menu, index) in menus"
        :key="index"
        class="module card p-3 m-0"
      >
        <unicon
          :name="menu.icon"
          width="30px"
          height="30px"
          class="mb-2"
          :fill="is_dark ? '#347cff' : 'black'"
        />
        <span class="d-block text-muted text-center">
          {{ menu.title }}
        </span>
      </router-link>
    </cards-list>
  </div>
</template>
<script>
import { menuData } from "@/constants/menu";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      menus: [],
    };
  },
  computed: {
    ...mapGetters(["user", "is_dark"]),
  },
  mounted() {
    this.menus = menuData
      .flatMap((menu) => {
        if (menu.key === "informations-enterprise") {
          return menu.children;
        }
        return [];
      })
      .filter((item) => item.visible.includes(this.user.role));
  },
};
</script>
<style lang="scss">
.list-items {
  margin: 0 auto;
  .module {
    margin: 1em;

    &.card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    @media (max-width: $break-md) {
      width: 100%;
      margin: 4px;
    }
  }
}
</style>